html,
body {
    font-family: "Raleway", sans-serif;
}

h1,
h2,
h3,
h4,
h5 {
    font-family: "Josefin Slab", serif;
}

a {
    cursor: pointer;
}

samp {
    font-family: "PT Mono", monospace;
}

dt {
    font-weight: normal;
}

hr {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
}

nav {
    font-family: "Raleway", sans-serif;
    font-weight: 500;

    .navbar-brand {
	div {
	    font-size: 1.10em;
	}
	
	div ~ div {
	    font-size: 0.75em;
	    margin-top: -5px;
	}
    }

    .navbar-nav {
	&:first-child {
	    .nav-link {
		min-width: 50px;
	    }
	}

	.dropdown-menu {
	    z-index: 2;
	}
    }
}

// Bootstrap class adjustments

.jumbotron {
    background-color: transparent;
}

.table {
    width: auto;
    
    td {
	border-color: rgba(0,0,0,.1);
    }
}

.table-hover {
    tr {
	cursor: pointer;
    }
}

.main {
    margin-top: 76px;
}

.no-hover {
    &:hover {    
	color: inherit;
	text-decoration: none;
    }
}

@import "headings";
