.package-structure {
    .fa-folder {
	color: #F1D791;
	
	& ~ span {
	    &::after {
		content: "/";
	    }
	}
    }

    .fa-file {
	color: #858585;
    }
}

.description-file {
    font-family: "PT Mono", monospace;

    div {
	padding-left: 1.5rem;
	text-indent: -1.5rem;
	
	.field {
	    cursor: pointer;
	    color: #BD4147;
	    
	    &::after {
		content: ":";
	    }
	}
    }
}
