.font-smaller {
    font-size: 0.75rem;
}

.font-large {
    font-size: 1.25rem;
}

.font-larger {
    font-size: 1.35rem;
}

.font-light {
    font-weight: 300;
}

.font-blokk {
    font-family: "BLOKK";
}

// text color
.text-code {
    color: #BD4147;
}

// borrowed from materialize, https://github.com/Dogfalo/materialize

$small-screen-up: 601px !default;
$medium-screen-up: 993px !default;
$large-screen-up: 1201px !default;
$small-screen: 600px !default;
$medium-screen: 992px !default;
$large-screen: 1200px !default;

$medium-and-up: "only screen and (min-width : #{$small-screen-up})" !default;
$large-and-up: "only screen and (min-width : #{$medium-screen-up})" !default;
$small-and-down: "only screen and (max-width : #{$small-screen})" !default;
$medium-and-down: "only screen and (max-width : #{$medium-screen})" !default;
$medium-only: "only screen and (min-width : #{$small-screen-up}) and (max-width : #{$medium-screen})" !default;

$range: $large-screen - $small-screen !default;
$intervals: 20 !default;
$interval-size: $range / $intervals !default;

.flow-text {
    //  font-weight: 300;
    $i: 0;
    @while $i <= $intervals {
	@media only screen and (min-width : 360 + ($i * $interval-size)) {
	    font-size: 1.0rem * (1 + (.01 * $i));
	}
	$i: $i + 1;
    }
    
    // Handle below 360px screen
    @media only screen and (max-width: 360px) {
	    font-size: 1.0rem;
    }
}

.flow-text-large {
    $i: 0;
    @while $i <= $intervals {
	@media only screen and (min-width : 360 + ($i * $interval-size)) {
	    font-size: 2.0rem * (1.2 + (.015 * $i));
	}
	$i: $i + 1;
    }
}

.flow-text-xl {
    $i: 0;
    @while $i <= $intervals {
	@media only screen and (min-width : 360 + ($i * ($interval-size * 1.5))) {
	    font-size: 4.25rem * (1.0 + (.015 * $i));
	}
	$i: $i + 1;
    }
}
