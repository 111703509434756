.rstudio-new-project {
    /* modified bootstrap classes */
    .list-group-item-action {
	&.active {
	    color: inherit;
	    background-color: #DCE6F5;
	    border-color: #DCE6F5;
	}
    }
    
    .card-footer {
	height: (49 / 2)px;
    }

    textarea {
	resize: none;
    }

    /* icons */
    .new-directory-icon {
	color: #E9F5FB;
    }

    .existing-directory-icon {
	color: #F1D791;
    }

    .version-control-icon {
	color: #B98D5A;
    }

    .r-package-icon {
	color: #92A3B5;
    }
    
    /* misc */
    .option-title {
	font-weight: 500;
    }
    
    .project-type {
	font-size: 0.75em;
    }

    *[data-toggle="tab"] {
	cursor: pointer;
    }

    .dropdown-select {
	button, a {
	    cursor: pointer;
	}
	
	.active {
	    background-color: white;
	}
    }

    /* font-awesome adjustments */
    .fa-check-square, .fa-square {
	&::before {
	    background-color: white;
	}
    }
}
