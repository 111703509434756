.terminal-card {
    margin: 0 0 0 0;
    font-family: "PT Mono", monospace;
    font-size: 1.25rem;
    border: none;
    background-color: #F5F1E6;

    & > div {
	padding: 10px 10px 10px 10px;
    }

    & ~ .terminal-card {
	margin-top: 16px;
    }

    pre {
	margin-bottom: 5px;
	font-family: inherit;
	font-size: inherit;
    }

    p {
	margin-bottom: 5px;
	
	&::before {
	    content: "$ ";
	}
    }

    .card-body {
	padding: 5px 10px 10px 10px;
    }

    .card-title {
	span {
	    font-size: 1rem;
	    
	    & ~ span {
		padding-left: 7px;
    		color: #FBE5B3;
	    }
	
	    &:first-child {
		color: #DEACAB;
	    }


	    &:last-child {
		color: #67A77A; //#556A5B;
	    }
	}
    }
}
